<template>
    <layout-full-width :title="$tc('overview')">

        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <div class="card shadow-sm">
                    <div class="card-body p-4">
                        <h4 class="header-title mt-2 mb-3">
                            {{ $t('pages.dashboard.new-campaign-guide.title') }}
                        </h4>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.1')"/>
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.2')"/>
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.3')"/>
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.4')"/>
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.5')"/>
                            <li class="list-group-item px-0" v-html="$t('pages.dashboard.new-campaign-guide.steps.6')"/>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                    <div class="card-body p-4">
                        <router-link :to="{ name: 'marketing-campaigns' }"
                                     class="btn btn-sm btn-outline-primary float-end">
                            {{ $tc('campaign', 2) }}
                        </router-link>
                        <button class="btn btn-sm btn-outline-secondary float-end me-1" @click="listCampaigns">
                            <font-awesome-icon icon="sync-alt"/>
                        </button>
                        <h4 class="header-title mt-2 mb-3">{{ $tc('latest-campaign', campaigns.total) }}</h4>

                        <ul v-if="!loading.CAMPAIGN_LIST && campaigns.total > 0" class="list-group list-group-flush">
                            <li class="list-group-item d-flex px-0">
                                <strong class="w-100">{{ $tc('name', 1) }}</strong>
                                <strong class="w-100">{{ $tc('action', 1) }}</strong>
                                <strong class="w-100">{{ $tc('provider', 1) }}</strong>
                            </li>
                            <li v-for="(campaign, i) in campaigns.list"
                                :key="'latest-campaigns-' + i"
                                class="list-group-item list-group-item-campaign d-flex px-0">
                                <strong class="text-truncate">
                                    <router-link v-if="campaign.campaignStatus === 'CAMPAIGN_COMPLETED'"
                                                 :to="{ name: 'marketing-campaign-log', params: { id: campaign.campaignUniqueId } }">
                                        {{ campaign.campaignDescShort }}
                                    </router-link>
                                    <router-link v-else
                                                 :to="{ name: 'marketing-campaign-settings', params: { id: campaign.campaignUniqueId } }">
                                        {{ campaign.campaignDescShort }}
                                    </router-link>
                                </strong>
                                <span class="truncate">Print-Mailing Automation</span>
                                <span class="truncate">Deutsche Post</span>
                            </li>
                        </ul>

                        <ul v-if="loading.CAMPAIGN_LIST" class="list-group list-group-flush">
                            <li class="list-group-item d-flex px-0">
                                <strong class="w-100">{{ $tc('name', 1) }}</strong>
                                <strong class="w-100">{{ $tc('action', 1) }}</strong>
                                <strong class="w-100">{{ $tc('provider', 1) }}</strong>
                            </li>
                            <li v-for="n in 5"
                                :key="'latest-campaigns-shimmer-' + n"
                                class="list-group-item d-flex px-0">
                                <component-shimmer class="me-3 w-100"/>
                                <component-shimmer class="me-3 w-100"/>
                                <component-shimmer class="me-3 w-100"/>
                            </li>
                        </ul>

                        <div v-if="!loading.CAMPAIGN_LIST && campaigns.total === 0"
                             class="d-flex flex-column text-center py-5">
                            <font-awesome-icon class="text-primary" icon="exclamation-triangle" size="3x"
                                               style="margin: 0 auto;"/>
                            <p class="mb-0 mt-2">{{ $t('pages.campaigns.no-campaigns-found') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card shadow-sm">
                    <div class="card-body p-4">
                        <h4 class="header-title mt-2 mb-3">{{ $tc('campaign-recipient', 2) }}</h4>
                        <p class="card-text">{{ $t('pages.dashboard.no-recipients-found') }}</p>
                    </div>
                </div>
            </div>
        </div>

    </layout-full-width>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentShimmer from "@/components/ComponentShimmer";

export default {
    name: "PageOverview",
    components: {
        ComponentShimmer,
        LayoutFullWidth
    },
    computed: {
        loading() {
            return {
                CAMPAIGN_LIST: this.$store.state.loading.CAMPAIGN_LIST
            }
        },
        campaigns() {
            return this.$store.state.CAMPAIGN.campaigns;
        }
    },
    methods: {
        listCampaigns() {
            this.$store.dispatch('CAMPAIGN/LIST', {
                page: 1,
                itemsPerPage: 5,
                limit: null,
                sort: [],
                term: null,
                filter: {},
                order: {'meta_insert_dt': 'DESC'}
            });
        }
    },
    beforeMount() {
        this.listCampaigns();
    }
}
</script>

<style lang="scss" scoped>

.card {
    .card-body {
        h4 {
            font-size: .9rem;
            text-transform: uppercase;
            letter-spacing: -.02rem;
            font-weight: 700;
        }

        ul.list-group {
            li.list-group-item-campaign {

                > * {
                    margin-right: 1rem;
                    flex-basis: 33.33%;
                    flex-grow: 1;
                }
            }
        }
    }
}

</style>
